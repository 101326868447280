<template>
  <div>
    <div class="row pt-5">
      <div class="col-12">
          <template>
            <b-card
              border-variant="success"
              header="Soporte"
              header-border-variant="success"
              align="center"
              class="mx-5"
            >
              <div>
                <b-embed
                  type="iframe"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfyROjS4arYIFAUT5wEeqvK53kIF9Ceh2ffW0AW6OoWIN011Q/viewform?embedded=true"
                  allowfullscreen
                ></b-embed>
              </div>
            </b-card>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
    };
  },
  components: {
    KTCodePreview
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Support", route: "support" },
      { title: "Support" }
    ]);
  }
};
</script>
